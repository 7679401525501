import request from '@/utils/request'

// 查询调账表单数据
export function findAgentBillAdjustForm(id) {
  return request({
    url: `/agent_bills/${id}/adjust_form`,
    method: 'get'
  })
}

// 调账
export function adjustAgentBill(id, data) {
  return request({
    url: `/agent_bills/${id}/adjust`,
    method: 'post',
    data
  })
}
