<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="调账"
      @ok="handleSubmit"
    >
      <a-spin :spinning="spinning">
        <a-form
          class="simiot-compact-form"
          :form="form"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
          @submit="handleSubmit"
        >

          <a-form-item
            label="原金额(元)"
            class="simiot-display-item"
          >
            {{ previousAmount | formatFloat }}
          </a-form-item>

          <a-form-item label="调账金额(元)">
            <a-space>
              <a-input-number
                style="width: 200px"
                :min="0.01"
                :max="10000000"
                :step="0.01"
                :precision="2"
                @change="handleChangeAmount"
                v-decorator="['amount', {
                  rules: [
                    { required: true, message: '请输入调账金额' },
                    { validator: checkAmount }
                  ]
                }]"
              />

              <a-select
                :default-value="currentGear"
                @change="handleChangeGear"
              >
                <a-select-option value="down">
                  调低
                </a-select-option>
                <a-select-option value="up">
                  调高
                </a-select-option>
              </a-select>
            </a-space>
          </a-form-item>

          <a-form-item
            label="调账后金额(元)"
            class="simiot-display-item"
          >
            {{ adjustedAmount | formatFloat }}
          </a-form-item>

          <a-form-item label="备注">
            <a-textarea
              v-decorator="['remark', {
                rules: [
                  { required: true, message: '请输入备注' },
                  { max: 200, message: '最多200个字符' }
                ]
              }]"
              :auto-size="{ minRows: 3, maxRows: 5 }"
            />
          </a-form-item>
        </a-form>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { findAgentBillAdjustForm, adjustAgentBill } from '@/api/agent_bill'

export default {
  name: 'Adjust',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    agentBillId: {
      type: Number,
      required: true
    }
  },
  data() {
    this.calculateAdjustedAmount = this.$lodash.debounce(this.calculateAdjustedAmount, 400)
    return {
      submitting: false,
      form: this.$form.createForm(this, { name: 'adjust_agent_bill' }),
      previousAmount: 0,
      adjustedAmount: '',
      spinning: true,
      currentGear: 'down'
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    // 检验调账金额
    checkAmount(rule, value, callback) {
      if (this.currentGear === 'down' && this.previousAmount < value) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('调账金额不能高于原金额')
        return
      }

      // eslint-disable-next-line standard/no-callback-literal
      callback()
    },

    // 处理改变档位（调高/调低）
    handleChangeGear(value) {
      this.clearAdjustedAmount()
      this.currentGear = value
      if (this.form.getFieldValue('amount')) {
        this.$nextTick(() => {
          this.form.validateFields(['amount'], { force: true })
          this.calculateAdjustedAmount()
        })
      }
    },

    // 处理改变调账金额
    handleChangeAmount(value) {
      if (value === this.form.getFieldValue('amount')) {
        return
      }
      this.clearAdjustedAmount()
      if (value && value > 0) {
        this.$nextTick(() => {
          this.calculateAdjustedAmount()
        })
      }
    },

    // 计算调整后的金额
    calculateAdjustedAmount() {
      const amount = this.form.getFieldValue('amount')
      if (amount && amount > 0) {
        if (this.currentGear === 'down') {
          this.adjustedAmount = this.previousAmount - amount
        } else {
          this.adjustedAmount = this.previousAmount + amount
        }
      }
    },

    // 清空调整后的金额
    clearAdjustedAmount() {
      this.adjustedAmount = undefined
    },

    fetchData() {
      this.spinning = true
      findAgentBillAdjustForm(this.agentBillId).then((res) => {
        if (res.code === 0) {
          this.previousAmount = res.data.previous_amount
        }
        this.spinning = false
      })
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const data = {
            ...values,
            adjusted_amount: this.adjustedAmount
          }
          adjustAgentBill(this.agentBillId, data).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
